/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from 'react'
import { connect } from 'react-redux'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import '@reach/skip-nav/styles.css'

import Footer from './Footer'
import SkipNavLink from './SkipNavLink'
import { theme, reset } from '../styles'
import i18n from '../../config/i18n'

import 'typeface-lora'
import 'typeface-source-sans-pro'
import 'typeface-lato'

import { init } from '../actions/cart';

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    ${'' /* h1 {
      font-size: 2.369rem !important;
    } */}
    ${'' /* h2 {
      font-size: 1.777rem !important;
    } */}
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`
const MainWrapper = styled.div`
  min-height: calc(100vh - 150px);
  > div {
    :nth-last-of-type(2) {
      &.picture {
        margin-top: 10rem;
        ${'' /* height: auto!important; */}
      }
    }
  }
`
const LocaleContext = React.createContext()

class ConnectedLayout extends React.Component {

  componentDidMount() {
    this.props.init();
  }

  render() {
    const { children, pageContext: { locale } } = this.props;

    return (
      <LocaleContext.Provider value={{ locale, i18n }}>
        <ThemeProvider theme={theme}>
          <MainWrapper>
            <Global styles={globalStyle} />
            <SkipNavLink />
            {children}
          </MainWrapper>
          <Footer>
            <div>
              <span>
                Follow Disch Remy on <a href="https://www.instagram.com/dischremy/" target="_blank">
                Instagram
              </a> and <a href="https://www.facebook.com/dischremy/" target="_blank">
                Facebook
              </a>
              </span>
            </div>
            <div>© 2019 Disch Remy - All rights reserved - website by Les Z</div>
          </Footer>
        </ThemeProvider>
      </LocaleContext.Provider>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(init())
});

const Layout = connect(null, mapDispatchToProps)(ConnectedLayout);
export { LocaleContext, Layout };